import { createContext, useEffect } from 'react';
import createUseMST from '@common/utils/create-use-mst';
import RootStore from './Root';

const rootStoreContext = createContext({});
const rootStore = RootStore.create({});

export function RootStoreProvider({ children }) {
  useEffect(() => {
    rootStore.init();
  });
  return <rootStoreContext.Provider value={rootStore}>{children}</rootStoreContext.Provider>;
}

const useRootStore = createUseMST(() => rootStoreContext);

export { rootStore };
export default useRootStore;
