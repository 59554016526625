import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import MiddlewareRoute from '@common/components/MiddlewareRoute';
import { ConfigProvider, Result } from 'antd';
import enUs from 'antd/lib/locale/en_US';
import routeInterceptor from './route-interceptor';
import { DynamicComponentArea } from '@common/controller/UIController';

const Admin = lazy(() => import('@/pages/Admin'));
const Login = lazy(() => import('@/pages/Login'));
const Demo = lazy(() => import('@/pages/Demo'));
const Contract = lazy(() => import('@/pages/Contract'));

const AppInner = observer(function AppInner() {
  return (
    <Router>
      <div>
        <Suspense fallback={null}>
          <Switch>
            <Route
              path="/404"
              exact
              render={() => <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />}
            />
            <Route path="/demo" exact component={Demo} />
            <MiddlewareRoute middleware={routeInterceptor} path="/login" exact component={Login} />
            <MiddlewareRoute middleware={routeInterceptor} path="/contract" component={Contract} />
            <MiddlewareRoute middleware={routeInterceptor} needAuth path="/" component={Admin} />
          </Switch>
        </Suspense>

        <DynamicComponentArea />
      </div>
    </Router>
  );
});

const App = observer(function App() {
  return (
    <ConfigProvider locale={enUs}>
      <AppInner />
    </ConfigProvider>
  );
});

export default App;
