import { tap } from 'rxjs/operators';
import { types } from 'mobx-state-tree';
import { AjaxDescribe, AjaxDescribeHelper, createAjaxDescribeMixins } from '@common/utils/describe-helper';
import { rootStore } from '..';

const AuthServer = types.compose(
  createAjaxDescribeMixins(
    'ajaxDescribe',
    AjaxDescribe,
    function () {
      this.createAjaxDescribe(
        'ajaxDescribe',
        (res) => [
          '/api/admin/auth/login',
          {
            username: '',
            password: '',
            ...res,
          },
          { method: 'post' },
        ],
        null,
        {
          postConnect(stream) {
            return stream.pipe(
              tap((data) => {
                if (data) {
                  rootStore.storage.set('jwt', data.auth);
                }
              })
            );
          },
        }
      );
    },
    'login'
  ),
  createAjaxDescribeMixins(
    'loginOutAjaxDescribe',
    AjaxDescribe,
    function () {
      this.createAjaxDescribe(
        'loginOutAjaxDescribe',
        () => ['/api/admin/auth/logout', null, { method: 'post' }],
        null,
        {
          postConnect(stream) {
            return stream.pipe(
              tap((data) => {
                if (data !== null) {
                  rootStore.storage.del('jwt');
                }
              })
            );
          },
        }
      );
    },
    'logout'
  )
);

const Auth = types.compose(AjaxDescribeHelper, AuthServer);

export default Auth;
