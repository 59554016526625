import { types } from 'mobx-state-tree';
import { AjaxDescribe, AjaxDescribeHelper, createAjaxDescribeMixins } from '@common/utils/describe-helper';
import { tap } from 'rxjs/operators';

const SystemSettingServer = createAjaxDescribeMixins(
  'ajaxDescribe',
  AjaxDescribe,
  function () {
    this.createAjaxDescribe(
      'ajaxDescribe',
      () => ['/api/admin/system/configuration'],
      (state) => this.updateFromAjaxJson(state.data),
      {
        postConnect: (stream) =>
          stream.pipe(
            tap((json) => {
              function sortFn(a, b) {
                return a.sort - b.sort;
              }

              if (json) {
                ['protection', 'coverage', 'gap'].forEach((key) => {
                  if (json[key]) {
                    json[key].sort(sortFn);
                  }
                });
              }
            })
          ),
      }
    );
  },
  'load'
);

const SystemSetting = types.compose(
  AjaxDescribeHelper,
  SystemSettingServer,
  types.model({ data: types.frozen() }).actions((self) => ({
    updateFromAjaxJson(json) {
      self.data = json || {};
    },
  }))
);

export default SystemSetting;
