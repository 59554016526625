import '@/style/main.less';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import './services/network-init';
import App from './App';
import { RootStoreProvider } from '@/models';
import 'antd/dist/antd.css';

function main() {
  ReactDOM.render(
    // <React.StrictMode>
    <RootStoreProvider>
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </RootStoreProvider>,
    // </React.StrictMode>
    document.getElementById('root')
  );
}

main();
