import { from } from 'rxjs';
import network from '@common/libs/network';
import { types } from 'mobx-state-tree';
import { AjaxDescribe, AjaxDescribeHelper, createAjaxDescribeMixins } from '@common/utils/describe-helper';
import { Observable, of } from 'rxjs';

const AccountServer = createAjaxDescribeMixins(
  'ajaxDescribe',
  AjaxDescribe,
  function () {
    this.createAjaxDescribe(
      'ajaxDescribe',
      () => [
        //这个接口需要登录态
        '/api/admin/auth/me',
        {},
        // 这个请求忽略401的跳转处理
        { ignore401: true },
      ],
      (state) => this.updateFromAjaxJson(state.data)
    );
  },
  'load'
);

const Account = types.compose(
  AjaxDescribeHelper,
  AccountServer,
  types
    .model({
      id: 0,
      username: '',
      email: '',
      avatar: '',
      nickname: '',
      phone: '',
      status: 0,
      last_login_time: '',
      last_login_ip: '',
      is_delete: 0,
      created_at: '',
      updated_at: '',
      department_id: 0,
      registraion: '',
    })
    .views((self) => ({
      get emailName() {
        return self.nickname + (self.email ? ` (${self.email})` : '');
      },
    }))
    .actions((self) => ({
      updateFromAjaxJson(data) {
        data &&
          Object.keys(initValue).forEach((key) => {
            if (key in data) {
              self[key] = data[key];
            }
          });
      },

      getLastUpdatedStream() {
        const { loading, inited, error } = self.ajaxDescribe;

        if (!loading && inited) {
          return of(self);
        } else {
          if (!loading && error) {
            self.load();
          }

          return new Observable((subscriber) => {
            self._describeEmitter.on('ajaxDescribe_loaded', function handler(e) {
              self._describeEmitter.on('ajaxDescribe_loaded', handler);
              subscriber.next(e);
              subscriber.complete();
            });
          });
        }
      },
    }))
);

const AccountListServer = createAjaxDescribeMixins(
  'ajaxDescribe',
  AjaxDescribe,
  function () {
    this.createStatusDataDescribe(
      'ajaxDescribe',
      () => from(network('/api/admin/admins/lists', { page: 1, page_size: 100 })),
      (state) => {
        this.updateFromAjaxJson(state.data);
      }
    );
  },
  'load'
);

const AccountList = types.compose(
  AjaxDescribeHelper,
  AccountListServer,
  types
    .model({
      rows: types.optional(types.array(Account), []),
    })
    .views((self) => ({
      get options() {
        return self.rows.map((item) => ({ label: item.emailName, value: item.id }));
      },
    }))
    .actions((self) => ({
      updateFromAjaxJson(data) {
        Object.assign(self, data);
      },
    }))
);

const initValue = Account.create().toJSON();

export { AccountList };
export default Account;
