import { types } from 'mobx-state-tree';
import Account from './Account';
import Auth from './Auth';
import Storage from '@common/models/Storage';
import { FeeList } from './Fee/Fee';
import SystemSetting from './SystemSetting';

function defaultObject(res) {
  return { ...res };
}

const RootStore = types
  .model({
    auth: types.optional(Auth, defaultObject),
    account: types.optional(Account, defaultObject),
    storage: types.optional(Storage, defaultObject),
    feeList: types.optional(FeeList, defaultObject),
    systemSetting: types.optional(SystemSetting, defaultObject),
  })
  .actions((self) => ({
    init() {
      self.account.load();
      console.log(self);
      // self.feeList.load();
      // self.systemSetting.load();
    },
  }));

export default RootStore;
