import { DEV_MODE, IS_LOCALHOST, IS_DEV, IS_TEST, IS_UAT, IS_PROD } from '@/env';

const protocol = IS_LOCALHOST ? 'https:' : '';
const fallbackHttp = window.location.protocol === 'https:' ? protocol : 'http:';
export const CDNADDR = `https://carnexcdn.azureedge.net/public`;
export const ADMINBACK = `${fallbackHttp}//carnexadminback.azurewebsites.net`;
export const ENDPOINT =
  DEV_MODE || IS_DEV
    ? // 开发环境
      `${protocol}//admin.cnxdev.fun`
    : IS_UAT
    ? // UAT环境
      `${protocol}//api.cnx.work.gd`
    : IS_TEST
    ? // test环境
      `${protocol}//admin.cnxdev.fun`
    : // 正式环境
      '';
// : `${protocol}//adminapi.carnex.ca`;
export const MOCKAPI = `http://127.0.0.1:4523/mock/433502`;

export const FRONT_END_ORIGIN = IS_PROD ? 'https://www.carnex.ca' : 'https://www.cnxdev.fun';
