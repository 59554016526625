import qs from 'qs';
import { get, set } from 'lodash';
import { network, axios } from '@common/libs/network';
import { rootStore } from '@/models';
import { ADMINBACK, CDNADDR, ENDPOINT, MOCKAPI } from './api';
import * as URLParse from 'url-parse';

export function replaceUrl(url, assign) {
  const l = window.location;
  console.warn('web 跳转 -> ', url);
  url && ((!assign && l.replace) || l.assign).call(l, url);
}

axios.defaults.paramsSerializer = params => {
  return qs.stringify(params);
};

network.addAlias('/api', ENDPOINT);
network.addAlias('/cdnApi', CDNADDR);
network.addAlias('/adminApi', ADMINBACK);
network.addAlias('/mockApi', MOCKAPI);
network.CDNADDR = CDNADDR;

function createCheckValidate(code) {
  return function (res, isError) {
    if (isError) return false;

    try {
      return typeof code === 'function' ? code(res.data) : +res.data.code === code;
    } catch (e) {
      return false;
    }
  };
}

network.createCheckValidate = createCheckValidate;
network.checkCodeEqual__200 = function (res) {
  return res.status === 200;
};
network.checkCodeEqual_1 = createCheckValidate(1);
network.checkCodeEqual_0 = createCheckValidate(0);
network.checkCodeEqual_200 = createCheckValidate(200);

Object.assign(network.config, {
  paramsFormat: 'json',
  checkValidate: network.checkCodeEqual_200,
  pickData(res) {
    try {
      const json = typeof res.data === 'string' ? JSON.parse(res.data) : res.data;
      return json.data;
    } catch (e) {
      return res.data;
    }
  },
});

network.errorHandler = function (err) {
  const httpStatus = get(err, 'response.status');

  function goto(path) {
    replaceUrl(
      URLParse(window.location)
        .set('pathname', '/' + (path || ''))
        .toString(),
      true
    );
  }

  // function goHome() {
  //   goto();
  // }

  switch (httpStatus) {
    case 401:
      if (!err.config.ignore401) {
        goto('/login?cb=' + window.location.href);
      }
      break;
    // case 403:
    //   if (!err.config.ignore403) {
    //     goHome();
    //   }
    //   break;
    // case 404:
    //   if (!err.config.ignore404) {
    //     goHome();
    //   }
    //   break;
    // case 405:
    //   if (!err.config.ignore405) {
    //     goHome();
    //   }
    //   break;
    default:
      break;
  }
};

network.interceptors.request.push(function (req) {
  const jwt = rootStore.storage.get('jwt');
  if (jwt) {
    set(req, 'headers.Authorization', jwt.token_type + ' ' + jwt.access_token);
  }
});
