const env = process.env;
const isProduction = env.NODE_ENV === 'production';

export const IS_LOCALHOST = !!env.REACT_APP_PORT; // 本地开发环境
export const IS_TEST = env.REACT_APP_ENV === 'test'; // test环境
export const IS_UAT = env.REACT_APP_ENV === 'uat'; // uat环境
export const IS_DEV = env.REACT_APP_ENV === 'dev'; // dev环境
export const IS_PROD = !env.REACT_APP_ENV && isProduction; // 正式环境
export const DEV_MODE = !isProduction && true; // 开发环境
export const MOCK_MODE = DEV_MODE && false; // 开发环境mock模式

window.envContext = {
  NODE_ENV: env.NODE_ENV,
  IS_LOCALHOST,
  IS_TEST,
  IS_UAT,
  IS_DEV,
  IS_PROD,
  DEV_MODE,
  MOCK_MODE,
};
