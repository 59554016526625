import { from } from 'rxjs';
import { types } from 'mobx-state-tree';
import { AjaxDescribe, AjaxDescribeHelper, createAjaxDescribeMixins } from '@common/utils/describe-helper';
import { FeeList as FeeListBase, FEE_TYPE } from '@common/models/Fee/Fee';
import network from '@common/libs/network';

const FeeListServer = createAjaxDescribeMixins(
  'ajaxDescribe',
  AjaxDescribe,
  function () {
    this.createStatusDataDescribe(
      'ajaxDescribe',
      () => from(Promise.all([network(`/api/admin/fee`), network('/api/admin/system/configuration/deposit')])),
      null,
      {
        onDataUpdate: (data) => {
          let list;

          if (data) {
            list = data[0];
            const deposit = data[1];
            if (deposit) {
              list.push({
                feeType: FEE_TYPE.PRE,
                value: deposit.money,
                name: 'Deposit amount',
                desc: `Deposit ${deposit.reserveTime}hours`,
              });
            }
          }

          this.updateFromAjaxJson(list);
        },
      }
    );
  },
  'load'
);

const FeeList = types.compose(AjaxDescribeHelper, FeeListServer, FeeListBase);

export * from '@common/models/Fee/Fee';
export { FeeList };
