import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import { runInterceptor } from '../utils/interceptor';

function noop() {}

export default function MiddlewareRoute({ component: Component, defalutReady, middleware, ...res }) {
  const [ready, setReady] = useState(defalutReady);
  const history = useHistory();

  useEffect(
    function () {
      runInterceptor(
        (middleware || []).concat(function () {
          setReady(true);
        }),
        noop,
        null,
        [res, history]
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [res.path]
  );

  return (
    <Route
      {...res}
      render={(props) => {
        if (ready) {
          return <Component {...props} />;
        }

        return null;
      }}
    />
  );
}
