import { rootStore } from './models';

function createPoint(fn) {
  return async function (res, route, history, next, exit) {
    const result = await fn(route, history);
    if (result === false) {
      exit();
    }
    next();
  };
}

const routeInterceptor = [
  createPoint(async function (route, history) {
    // 如果当前路由需要要求登录
    if (route.needAuth) {
      // 加载用户信息
      await rootStore.account.getLastUpdatedStream().toPromise();
      // 有id说明有登录态
      if (!rootStore.account.id) {
        history.push('/login?cb=' + window.location.href);
        return false;
      }
    }
  }),
];

export default routeInterceptor;
