import { getLocalStorage, modifyStorage } from '@common/utils/storage';
import { types } from 'mobx-state-tree';

// Storage实体，自动保存到本地和从本地恢复。
const Storage = types
  .model({
    key: 'root_storage',
    useSessionStorage: false,
    value: types.frozen(),
  })
  .actions((self) => ({
    afterCreate() {
      self.value = JSON.parse(getLocalStorage(self.key, false, self.useSessionStorage) || '{}');
    },
    get(key) {
      return typeof key === 'undefined' ? self.value : self.value[key];
    },

    del(key) {
      self.set(key, '', true);
    },

    // 单个字段操作
    set(key, value, isDel) {
      const res = { ...self.value };

      modifyStorage(
        self.key,
        (json) => {
          if (isDel) {
            delete res[key];
            delete json[key];
          } else {
            res[key] = value;
            json[key] = value;
          }
        },
        self.useSessionStorage
      );

      self.value = res;
    },

    // 合并操作
    assign(obj) {
      modifyStorage(
        self.key,
        (json) => {
          Object.assign(json, obj);
          self.value = json;
        },
        self.useSessionStorage
      );
    },
  }));

export default Storage;
